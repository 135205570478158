<template>
    <div>
        <ebaRegistrationStep ref="RegistrationStep" step-title="Communications.">
            <div class="registrationText">
                By signing up to Experian business marketing communications you will receive the latest research, insight, news and invites to events and webinars. Experian offers a wide range of products and services. We will do our best to send you only communications that we deem to be relevant to you, your job and your business. You can change your mind at any time by updating your preferences in your personal account setting.
            </div>
            <div class="registrationText">
                Would you like to hear from us?
            </div>
            <div class="registrationInput">
                <ebaRadioGroup
                    id="radMarketingGroup"
                    :items="marketingPreferences"
                    v-on:option-selected="marketingPreferenceSelected">
                </ebaRadioGroup>
            </div>
            <p v-if="validationMessage" class="validation-message">
                {{ validationMessage }}
            </p>
            <br/>
            <br/>
        </ebaRegistrationStep>
    </div>
</template>

<script>

    import {get as config} from '../../scripts/common/config';

    export default {
        name: 'CommsAndMarketing',
        props: {
            registrationData: {
                type: Object
            }
        },
        mounted() {
            this.$emit('clearHeaderText');
            this.$emit('clearCustomProceedText');
            if (this.registrationData.MarketingOptIn !== null && this.registrationData.MarketingOptIn !== undefined) {
                const radioGroup = document.querySelectorAll("#radMarketingGroup input[type='radio']");
                radioGroup.forEach(radio => {
                    if (radio.value === String(this.registrationData.MarketingOptIn)) {
                        radio.checked = true;
                    }
                });
            }
        },
        data() {
            return {
                thirdPartyServices: config().ThirdPartyServices,
                validationMessage: '',
                marketingPreferences: [
                    {
                        "id": true,
                        "text": "I would like to receive Experian marketing communications"
                    },
                    {
                        "id": false,
                        "text": "I do not want to receive Experian marketing communications"
                    }
                ]
            };
        },
        methods: {
            marketingPreferenceSelected(val) {
                this.validationMessage = '';
                this.registrationData.MarketingOptIn = val;
                console.log(val);
            },
            isStepValid() {
                var self = this;

                return new Promise(function (resolve, reject) {
                    var isValid = true;
                    self.validationMessage = '';

                    if (self.registrationData.MarketingOptIn === null || self.registrationData.MarketingOptIn === undefined) {
                        self.validationMessage = 'Marketing preferences are required';
                        isValid = false;
                    }

                    resolve(isValid);
                });
            }
        }
    };
</script>

<style lang="less" scoped>

    @import '../../components/common/assets/css/common.less';

    .validation-message {
        position: absolute;
        display: block;
        margin-top: 100px;
        padding-left: 55px;
        color: red;
        font-size: 14px;
    }

</style>
