var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "ebaRegistrationStep",
        { ref: "RegistrationStep", attrs: { "step-title": "Communications." } },
        [
          _c("div", { staticClass: "registrationText" }, [
            _vm._v(
              "\n            By signing up to Experian business marketing communications you will receive the latest research, insight, news and invites to events and webinars. Experian offers a wide range of products and services. We will do our best to send you only communications that we deem to be relevant to you, your job and your business. You can change your mind at any time by updating your preferences in your personal account setting.\n        "
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "registrationText" }, [
            _vm._v("\n            Would you like to hear from us?\n        "),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "registrationInput" },
            [
              _c("ebaRadioGroup", {
                attrs: {
                  id: "radMarketingGroup",
                  items: _vm.marketingPreferences,
                },
                on: { "option-selected": _vm.marketingPreferenceSelected },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.validationMessage
            ? _c("p", { staticClass: "validation-message" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.validationMessage) +
                    "\n        "
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("br"),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }